/* --- start of injectGlobal --- */
@font-face {
  font-family: 'Berlingske-Serif-md';
  src: url('https://cdn.kounta.com/core/fonts/berlingske/BerlingskeSerif-Md.eot');
  src: url('https://cdn.kounta.com/core/fonts/berlingske/BerlingskeSerif-Md.eot?#iefix')
      format('embedded-opentype'),
    url('https://cdn.kounta.com/core/fonts/berlingske/BerlingskeSerif-Md.woff2')
      format('woff2'),
    url('https://cdn.kounta.com/core/fonts/berlingske/BerlingskeSerif-Md.woff')
      format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Akkurat-Regular';
  src: url('https://cdn.kounta.com/core/fonts/akkurat/lineto-akkurat-regular.eot');
  src: url('https://cdn.kounta.com/core/fonts/akkurat/lineto-akkurat-regular.eot?#iefix')
      format('embedded-opentype'),
    url('https://cdn.kounta.com/core/fonts/akkurat/lineto-akkurat-regular.woff2')
      format('woff2'),
    url('https://cdn.kounta.com/core/fonts/akkurat/lineto-akkurat-regular.woff')
      format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Akkurat-Regular';
  src: url('https://cdn.kounta.com/core/fonts/akkurat/lineto-akkurat-bold.eot');
  src: url('https://cdn.kounta.com/core/fonts/akkurat/lineto-akkurat-bold.eot?#iefix')
      format('embedded-opentype'),
    url('https://cdn.kounta.com/core/fonts/akkurat/lineto-akkurat-bold.woff2')
      format('woff2'),
    url('https://cdn.kounta.com/core/fonts/akkurat/lineto-akkurat-bold.woff')
      format('woff');
  font-weight: bold;
  font-style: normal;
}

html,
body {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Akkurat-Regular';
}

*,
*:before,
*:after {
  box-sizing: inherit;
}
/* --- end of injectGlobal --- */

body {
  margin: 0px;
}

.main {
  display: flex;
  height: 100vh;
  flex-direction: row;
}

.content {
  overflow: scroll;
}

.content-container {
  padding: 24px 16px;
}

@media screen and (min-width: 768px) {
  .content-container {
    padding: 24px 48px;
  }
}

/* TODO Remove when this ticket is fixed
 https://kounta.atlassian.net/browse/CHAM-327 */
div[class*="AppHeader__TopBar"] {
  min-height: 56px;
}
